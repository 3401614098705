select {
    font-size: 1rem;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $light-gray;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $light-gray;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color:$light-gray;
}
.sidebar {
    & h2 {
        color:$titlelook-gray1;
        font-weight:400;
        @include breakpoint(small) {
            font-size: rem-calc(20px);
        }

        @include breakpoint(medium up) {
            font-size: rem-calc(22px);
        }
    }

    & h3, .h3 {
        font-size: 1.2rem;
    }

    & label {
        font-size: 1.1rem;
        color: $dark-gray;
    }
}

.main-section {
    & h1 {
        @include breakpoint(small only) {
            font-size: 1.75rem;
        }
    }

    & h2 {
        color: $titlelook-gray1;
        font-weight:400;
        @include breakpoint(small) {
            font-size: rem-calc(22px);
            padding: .5rem;
            border-radius: 5px;
        }

        @include breakpoint(medium up) {
            font-size: rem-calc(24px);
            background: none;
        }
    }

    & h3 {
        @include breakpoint(small) {
            font-size: 1.1rem;
        }

        @include breakpoint(medium up) {
            font-size: 1.3rem;
        }
    }
}

.look-content {
    & h2 {
        font-weight: 400;

        @include breakpoint(small) {
            font-size: rem-calc(22px);
        }

        @include breakpoint(medium up) {
            font-size: rem-calc(24px);
        }
    }

    & h3 {
        @include breakpoint(small) {
            font-size: rem-calc(20px);
        }

        @include breakpoint(medium up) {
            font-size: rem-calc(22px);
        }
    }

    & .lookFilter {
        font-size: 1.3rem;
    }
}

.reveal {
    & h1 {
        border-bottom: 1px solid $light-gray;
        padding-bottom: 3px;
        font-size: rem-calc(22px);
    }

    td {
        font-size: 1rem;
        padding: .25rem .125rem;
    }
}

td a {
    color:$titlelook-orange1;
    text-decoration: underline;
}

#customerLookTemplateTab {
    & label {
        font-size: 1.125rem;
    }
}

.summaryDl {
    & dt {
        font-size: rem-calc(14px);
        color:$dark-gray;
    }

    & dd {
        font-size:rem-calc(12.5px);
        margin-bottom: 1.25rem;
    }

}

.summaryDlLarge {
    & dt {
        font-size: 1.125rem;
        color: $titlelook-gray1;
        font-weight: 400;
        border-bottom: 1px solid #efefef;
        margin-right: 1rem;
        margin-bottom: 0.25rem;
    }

    & dd {
        font-size: 1rem;
        margin-bottom: 1.25rem;
        & i{
            color:medium-gray;
        }
    }

    @include breakpoint(medium up) {
        & dt {
            font-size: 1.2rem;
            border-bottom: 1px solid #efefef;
            margin-right: 2rem;
            margin-bottom: 0.5rem;
        }

        & dd {
            font-size: 1rem;
            margin-bottom: 2rem;
        }
    }
}

#additionalResourcesPanel, #documentsPanel {
    & .h3 {
        border-bottom: 1px solid #efefef;

        @include breakpoint(small) {
            font-size: 2.0rem;
        }

        @include breakpoint(medium up) {
            font-size: 2.0rem;
            border-bottom: 1px solid #efefef;
        }
    }

    & .h4 {
        font-weight: 400;

        @include breakpoint(small) {
            font-size: 1.3rem;
        }

        @include breakpoint(medium up) {
            font-size: 1.5rem;
        }
    }

    & .cell {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    & .cell:last-child {
        padding-top: 0;
        padding-bottom: 0;
    }

    & .cell:first-child {
        padding-top: 0;
        padding-bottom: 0;
    }
}

#order-progress-status {
    font-size: 2rem;
    font-family: $header-font-family;
    color:$dark-gray;
}
a.inverseLink{
    color:$white;
}

.tableNoWrap{
    white-space:pre;
}

table tr.temporaryRow td {
    background: #d5f5ff;
    color: #939393;

    & span.temporaryRowTitle {
        &::after {
            color: $dark-gray;
            content: ' New ';
             font-size:.8rem;
             font-weight:bold;
             font-style:italic;

        }
    }
}

.noDataMessage{
    font-size:1.2rem;
    padding:1rem 0;
    display:block;
}

sup.xsmall{
    font-size:50%;
    top:-1em;

    &.latest{
        color:$success-color;
    }
}
.dropdown-pane li{
    font-size:1.25rem;
}
#filterLabel {
    font-size: 1.1rem;
}
footer a, .lookViewDisclaimerText a{
    text-decoration:underline;
}
#lookGroupAddress{
    font-size:1.1rem;
}