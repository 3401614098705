.myaccount-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-user);
    }
}

.signout-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-sign-out-alt);
    }
}

.report-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-file-alt);
    }
}

.titlelook-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-laptop-code);
    }
}

.remove-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-trash-alt);
    }
}

.filter-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-filter);
    }
}

.sync-icon {
    @extend %fa-icon;
    @extend .fas;
    font-size: 2rem;

    &:before {
        content: fa-content($fa-var-sync);
    }

    &:hover {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.search-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-search);
    }
}

.history-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-history);
    }
}

.calendar-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-calendar-alt);
    }
}

.map-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-map-marked-alt);
    }
}

.eye-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-eye);
    }
}

.starting-document-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-file-import);
    }
}

.external-document-icon {
    &:after {
        @extend %fa-icon;
        @extend .fas;
        font-size: 0.8rem;
        margin-left:5px;
        content: fa-content($fa-var-external-link-square-alt);
        color: $titlelook-orange1;
    }

}

.unlinked-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-unlink);
    }
}

.linked-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-link);
    }
}

.unmatched-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-not-equal);
    }
}

.matched-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-equals);
    }
}

.change-view-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-stream);
    }
}

.check-icon {
    @extend %fa-icon;
    @extend .fas;
    font-size: 1.25rem;
    color: $success-color;

    &:before {
        content: fa-content($fa-var-check-circle);
    }
}

.flag-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-flag);
    }

    &.red-flag {
        color: #a92222;
    }

    &.yellow-flag {
        color: #e3d108;
    }

    &.gray-flag {
        color: #818181;
    }
}

.action-item-icon {
    @extend %fa-icon;
    @extend .fas;
    color: $gold;

    &:before {
        content: fa-content($fa-var-exclamation-circle);
    }
}

.close-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-times-circle);
    }
}

.configure-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-cog);
    }
}

.users-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-users);
    }
}

.user-icon {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-user);
    }
}

.mapItem {
    &:before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-map-marker-alt);
    }
}

.zipItem {
    &:before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-file-archive);
    }
}

.mergedItem {
    &:before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-object-group);
    }
}

.formItem {
    &:before {
        @extend %fa-icon;
        @extend .fab;
        content: fa-content($fa-var-wpforms);
    }
}

.externalLinkItem {
    &:before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-external-link-alt);
    }
}

.UpDownIcon {
    &:before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-chevron-down);
    }

    &.collapsed {
        &:before {
            content: fa-content($fa-var-chevron-up);
        }
    }
}

.eyeCon {
    &:before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-eye);
        color: #7f5ece
    }

    &.closed {
        &:before {
            content: fa-content($fa-var-eye-slash);
            color: #c7c7c7;
        }
    }
}

.bookmarkIcon {
    @extend %fa-icon;
    @extend .far;


    &:before {
        content: fa-content($fa-var-bookmark);
        display: inline-block;
        transition: all .5s;
    }

    &:hover:before {
        -webkit-transform: translateY(-5px);
        -moz-transform: translateY(-5px);
        -o-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

i.muted{color:$titlelook-gray2;}
i.primary{color:$primary-color;}
i.orange1{color:$titlelook-orange1;}
i.success{color:$success-color;}


%iconInfoBox { 
    font-size: 3rem;
    color: #ddd;
    position:relative;
    left:10px;
    top:10px;
    height:20px;
} 
.infoContainer {
    border-radius:5px;
    background:#fff;
    min-width:250px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    @extend %fa-icon;
    @extend .fas;

    &.customerBox{
    &:before {
        @extend %iconInfoBox;
        content: fa-content($fa-var-building);
    }
}
    &.groupBox{
        &:before {
            @extend %iconInfoBox;
            content: fa-content($fa-var-layer-group);
        }
}
&.createdBox{
    &:before {
        @extend %iconInfoBox;
        content: fa-content($fa-var-calendar-plus);
    }
}
&.lastModifiedBox{
    &:before {
        @extend %iconInfoBox;
        content: fa-content($fa-var-calendar-check);
    }
}
}

.deleteIcon{
    width:100%;
    &:before {
    content: url(/img/noun-delete-157604.svg);
    position: relative;
    top: 16px;
    left: 95%;
    width: 15px;
    display: block;
    z-index: 1;
    }
}