#offCanvasMenu .h1 {
    font-size: 2rem;
}

.dropdown.menu > li > a {
    padding: .7rem .5rem;
}

.dropdown.menu a {
    color: $titlelook-gray1;
}

.hamburgermenu-icon {
    border: none;
    padding: 0.5rem;
    font-size: 1.5rem;
    @extend %fa-icon;
    @extend .fas;

    &.small {
        font-size: 1rem;
        padding: 0.125rem;
    }

    &:before {
        content: fa-content($fa-var-bars);
    }
}

.title-bar-title {

    img {
        width:200px;
        max-width: 200px;
        
    }
}

.off-canvas {
    background: $dark-gray;
    .close-button, .close-button:focus {
        color: #fff;
        outline: none;
    }

    .vertical.tabs {
        border-bottom: none;
        background: none;
    }

    .myaccount {
        max-width: 35px;
        border-radius: 50%;
    }

    .my-account-info {
        padding-top: 2rem;
        border-top: 1px solid #ffffff;
        margin-top: 20px;

        p {
            color: #fff;
            font-weight: bold;
        }

        ul li {
            padding: 5px 0;
        }
    }

    .user-name {
        color: #ffffff;
        padding-left: 20px;
    }

    a {
        color: #fff;
    }

    .drilldown a {
        background: none;
        padding: 15px 0;
        font-size: 1.2rem;
    }

    &.position-right{
        background:$white;

        .close-button, .close-button:focus {
            color: #000;
            outline: none;
            font-size:rem-calc(12);
        }

        h1{
            font-size:rem-calc(14);
            margin-top:1rem;
        }

        .dropdown-label{ 
        .input-label{
            font-size:rem-calc(10);
            padding:0 2px;
            top:-2px;
        }
        input{
            padding: 5px 8px;
            font-size:rem-calc(10);
            height:25px;

        }
    }
    select{
        font-size:rem-calc(10);
        height: 25px;
    }

    dl{
        dt{
            font-size:rem-calc(12);
            width:100%;
            border:1px solid #ddd;
            border-radius:5px;
            padding:2px 5px;
        }
        dd{
            font-size:rem-calc(10);
        }
    }
    .accordion-title{
        font-size:rem-calc(11);
    }


    hr {
        margin: 0.5em 0;
        text-align: center;
        border: none;
      }
      
      hr:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $titlelook-orange2;
        margin: 0 0.4em;
      }
      
      hr:after {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $mainspring-blue4;
        margin: 0 0.4em;
      }
      .accordion{
        label {
            font-size:rem-calc(10);
        }
      }
      .input-group-label{
        padding-left:0;
        background:none;
        border:none;
      }
      .input-group{
        margin-top:5px;
        margin-bottom:5px;
      }
      textarea{
        font-size:rem-calc(10);
      }
}
}

.top-bar {
    padding: 20px 0;
    border-bottom:1px solid $titlelook-gray2;

    .menu {
        a {
            font-size: 1.25rem;
        }

        font-weight: 500;

        span {
            font-weight: 700;
        }
    }

    .logo {
        padding: 0 20px;

        img {
            width: 300px;
            max-width: 300px;
        }
    }

    .top-bar-right {
        padding: 0 20px;
    }

    .myaccount {
        max-width: 35px;
        border-radius: 50%;
    }

    .myAccountMenuItem {
        & span, i {
            color: $white;
        }
    }

    .myAccount-dropdown {
        padding-left: 20px;
        margin-left: 10px;
        border-left: 1px solid #efefef;
    }
}

.heroBar {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;

    @include breakpoint(medium up) {
        padding: 10px 0;
    }

    h1 {
        margin: 0px;

        @include breakpoint(small) {
            margin: 10px 0;
            font-size: 20px;
        }

        @include breakpoint(medium up) {
            margin: 10px 0;
            font-size: 2rem;
        }
    }

    nav {

        ul {
            margin: 0px;

            li {
                font-weight: 600;
                font-size: rem-calc(12);
            }
        }
    }

    .excecutive-view {
        margin-bottom: 10px;
    }
}

.top-bar .dropdown.button {
    background: none;
    color: $white;
    padding: 0px;
}

.top-bar ul.submenu {
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    min-width: 240px;

    li {
        padding: 0;

        a {
            color: $mainspring-blue1;
            padding: 10px;
            margin: 0px;
            display: block;
            transition: 0.5s;
            background: #fff;

            &:hover {
                background: rgba($mainspring-blue3, 0.2);
            }
        }
    }
}

.dropdown-pane {
    ul #headerUserBox {
        background: none;

        li a {
            color: $mainspring-blue;
            padding: 10px 0;
        }
    }
}

.title-bar {
    border-bottom: 1px solid $titlelook-gray2;
    & button {
        background: none;
        color: $mainspring-gray;
        padding: .75rem 0.25rem;
    }

    & button:hover,
    button:focus {
        background: none;
        color: darken($mainspring-gray, 20%);
    }
}

#mobileUserMenuItems {
    & li {
        margin: 5px 0;
    }
}

hr.left {
    margin-left: 0;
    max-width: none;
}

.breadcrumbRow {
background-color:$white;
border-bottom:1px solid $titlelook-gray2;
}
