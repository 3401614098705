.newOrderIDInput {
    font-size: 1.1rem;
}
.actionBar {
    padding: 0.5rem;
    background-color: $medium-gray;

    & .button {
        margin: 0.25rem 0;

        @include breakpoint(medium) {
            margin: 1rem 0.25rem;
        }
    }
}