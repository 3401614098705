div.dataTables_wrapper {
    width: 100%;
    margin: 0 auto;
}

table.dataTable thead .sorting {
background: url("../img/sort_both.png") no-repeat center left !important;
padding: 0 20px;
}

table.dataTable thead .sorting_asc {
    background: url("../img/sort_asc.png") no-repeat center left !important;
    font-weight: bold;
    padding: 0 20px;
}

table.dataTable thead .sorting_desc {
    background: url("../img/sort_desc.png") no-repeat center left !important;
    font-weight: bold;
    padding: 0 20px;
}

table.dataTable thead .sorting_asc_disabled {
    background: url("../img/sort_asc_disabled.png") no-repeat center left !important;
    padding: 0 20px;
}

table.dataTable thead .sorting_desc_disabled {
    background: url("../img/sort_desc_disabled.png") no-repeat center left !important;
    padding: 0 20px;
}

.select-checkbox {
    padding: 5px 10px;
    &::before {
        margin-top: -12px !important;
    }
    &::after {
        margin-top: -20px !important;
    }
}

table .has-tip {
    cursor: pointer;
    font-weight: normal;
    border-bottom: none;
}


.table-row-error.odd,
.table-row-error.even {
    background-color: $warning-color;
    color: $white;
}

.tableStatusLabel {
    &::after {
        @extend %fa-icon;
        @extend .fas;
        position: relative;
        margin-left: 5px;
        font-size:.8rem;
    }

    &.phrasesUnmatchedLabel {
        color: $warning-color !important;

        &::after {
            content: fa-content($fa-var-exclamation-circle);
        }
    }

    &.docUnlinkedLabel {
        color: $warning-color !important;

        &::after {
            content: fa-content($fa-var-times);
        }
    }

    &.docLinkedLabel {
        color: $success-color !important;

        &::after {
            content: fa-content($fa-var-check);
        }
    }
}
.dataTables_filter input {
    border-color: $light-gray;
    &:hover {
        box-shadow: inset 0 1px 2px transparent,0 0 3px $titlelook-gray2;
        border: 1px solid $mainspring-blue3;
        outline: none;
    }

    &:focus {
        box-shadow: inset 0 1px 2px transparent,0 0 6px $mainspring-blue3;
        border: 1px solid $mainspring-blue3;
        outline: none;
    }
}