.card-divider,
.card-divider label {
    color: $white;
}
.card-divider h3 {
    font-size: 1.25rem;
}

.reveal{
    & .accordion#shareNSendItems{
        & .accordion-item{
            padding:10px 5px;
        }
        & .accordion-item:nth-of-type(2n){
            background:#f5f5f5;
        }
    }
}

.selectShareCard{
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    margin:0.5rem;
    padding:0.5rem;
}