ul.tabs {
    border-bottom: 1px solid #efefef;
    border-top: 0;
    border-right: 0;
    border-left: 0;
}

.reveal .tabs-title,
.tabs-panel .tabs-title {
    & a {
        font-size: 1rem;
        padding: 0.5rem 0.25rem;
    }
    & > a[aria-selected="true"] {
        background-color: $mainspring-blue;
        color: $white;
    }
}
.tabs-title > a {
    font-size: 1.25rem;
    @include breakpoint(large) {
        font-size: 1.5rem;
    }
}
.tabs.flex {
    display: flex;
  
    .tabs-title {
      flex: 1 0 0;
      text-align: center;
  
      & + .tabs-title {
        border-left: 1px solid #ddd;
      }
    }
  }


ul.leftNavIconsMenu {
    & li {
        & a::before {
            @extend %fa-icon;
            @extend .fas;
            width: 35px;
        }

        & a.infoMenuLink::before {
            content: fa-content($fa-var-info-circle);
        }

        & a.usersMenuLink::before {
            content: fa-content($fa-var-users);
        }

        & a.officesMenuLink::before {
            content: fa-content($fa-var-building);
        }

        & a.codeBookMenuLink::before {
            content: fa-content($fa-var-book);
        }

        & a.lookTemplateMenuLink::before, a.userOrdersMenuLink::before, a.coverPageTemplateMenuLink::before {
            content: fa-content($fa-var-file-alt);
        }

        & a.featuresMenuLink::before {
            content: fa-content($fa-var-sitemap);
        }

        & a.groupsMenuLink::before {
            content: fa-content($fa-var-layer-group);
        }

        & a.userLoginsMenuLink::before {
            content: fa-content($fa-var-user-clock);
        }
    }
}