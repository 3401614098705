.button.primary {
    transition: ease-in-out .5s;
    text-decoration:none;
    &::after {
        @extend %fa-icon;
        @extend .fas;
        position: relative;
        margin-left: 5px;
        font-size: 0.8rem;
    }


    &:hover:not([disabled]) {
        box-shadow: 0 2px 5px $titlelook-gray1;
        -webkit-transform: translateY(-1px);
        -moz-transform: translateY(-1px);
        -o-transform: translateY(-1px);
        -ms-transform: translateY(-1px);
        transform: translateY(-1px);
    }

    &.clear:hover, &.clear:active {
        color: $mainspring-blue3;
        box-shadow: none;

        & svg {
            fill: $mainspring-blue3;
        }
    }

    &:active:not([disabled]) {
        background-color: $mainspring-blue3;
    }

    &.createNewButton::after {
        content: "\f0fe";
    }

    &.syncButton::after {
        content: fa-content($fa-var-sync);
    }

    &.submitButton::after {
        content: fa-content($fa-var-chevron-right);
    }

    &.addNewButton::before {
        @extend %fa-icon;
        @extend .fas;
        position: relative;
        margin-right: 5px;
        font-size: 0.6rem;
        content: fa-content($fa-var-plus);
    }    

    &.submitButtonSubmitting::after {
        -webkit-animation: fa-spin 2s infinite linear;
        animation: fa-spin 2s infinite linear;
        content: fa-content($fa-var-spinner);
        position: relative;
        left: 0;
    }
}

    .downloadButton {
        &::before {
            @extend %fa-icon;
            @extend .fas;
            content: fa-content($fa-var-download);
            margin-right: 5px;
            transition: all .5s;
        }

        &:hover::before {
            -webkit-transform: translateY(-5px);
            -moz-transform: translateY(-5px);
            -o-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            transform: translateY(-5px);
        }
    }



    .redoButton {
        &::after {
            @extend %fa-icon;
            @extend .fas;
            content: fa-content($fa-var-redo-alt);
            margin-left: 5px;
        }
    }

    .editButton {
        &::after {
            @extend %fa-icon;
            @extend .fas;
            content: fa-content($fa-var-edit);
            margin-left: 5px;
            transition: all .5s;
        }
    }

    .saveButton {
        &::after {
            @extend %fa-icon;
            @extend .fas;
            content: fa-content($fa-var-save);
            margin-left: 5px;
        }
    }

    .searchButton {
        &::after {
            @extend %fa-icon;
            @extend .fas;
            content: fa-content($fa-var-search);
            margin-left: 5px;
        }
    }


    .filterButton::after {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-filter);
        margin-left: 5px;
    }


    .mobileAddNewButton {
        position: absolute;
        right: 2%;
        bottom: 10%;
        border-radius: 10%;
        padding: 1.25rem;
        border: 2px solid #efefef;
        box-shadow: 0 0 10px 4px #999;

        &::before {
            font-family: "Font Awesome 5 Free";
            content: "\f0fe";
            font-size: 2rem;
        }
    }

.mobileActionSheetButton {
    position: absolute;
    right: 2%;
    bottom: 10%;
    border-radius: 10%;
    padding: 1.25rem;
    border: 2px solid #efefef;
    box-shadow: 0 0 10px 4px #999;

    &::before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-cogs);
        font-size: 2rem;
    }
}

    .lookMobileMenu {
        & .button {
            margin: 0 2px;
        }

        & .button::before {
            @extend %fa-icon;
            @extend .fas;
            display: block;
            transition: all .5s;
        }

        & .button:hover::before {
            -webkit-transform: translateY(-5px);
            -moz-transform: translateY(-5px);
            -o-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            transform: translateY(-5px);
        }

        & .additionalResourcesButton::before {
            content: fa-content($fa-var-puzzle-piece);
        }

        & .documentsButton::before {
            content: fa-content($fa-var-folder-open);
        }

        & .reportButton::before {
            content: fa-content($fa-var-file-pdf);
        }

        & .summaryButton::before {
            content: fa-content($fa-var-laptop-code);
        }
    }

    .lookMobileShareButton {
        &::before {
            @extend %fa-icon;
            @extend .fas;
            display: block;
            transition: all .5s;
            content: fa-content($fa-var-share-alt);
            margin-bottom: 2px;
        }

        &:hover::before {
            -webkit-transform: translateY(-5px);
            -moz-transform: translateY(-5px);
            -o-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            transform: translateY(-5px);
        }
    }

    .lookMobileFollowButton {
        &::before {
            @extend %fa-icon;
            @extend .fas;
            display: block;
            transition: all .5s;
            content: fa-content($fa-var-user-plus);
            margin-bottom: 2px;
        }

        &:hover::before {
            -webkit-transform: translateY(-5px);
            -moz-transform: translateY(-5px);
            -o-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            transform: translateY(-5px);
        }
    }

    .lookMobileMenuToggler {
        &::before {
            @extend %fa-icon;
            @extend .fas;
            display: block;
            transition: all .5s;
            content: fa-content($fa-var-window-close);
        }

        &.collapsed::before {
            content: fa-content($fa-var-bars);
        }
    }

    .lookMobileAddressToggler {
        &::before {
            @extend %fa-icon;
            @extend .fas;
            display: block;
            transition: all .5s;
            content: fa-content($fa-var-window-close);
        }

        &.collapsed::before {
            content: fa-content($fa-var-map-marked-alt);
        }
    }

    .clearCacheButton {
        &::before {
            @extend %fa-icon;
            @extend .fas;
            margin-right: 5px;
            transition: all .5s;
            content: fa-content($fa-var-dumpster-fire);
        }

        &:hover::before {
            -webkit-transform: translateY(-5px);
            -moz-transform: translateY(-5px);
            -o-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            transform: translateY(-5px);
        }
    }

    .tableViewButton {
        padding: 5px 0;

        & span {
            font-weight: 300 !important;
            text-decoration: underline;
        }

        &::before {
            @extend %fa-icon;
            @extend .fas;
            display: block;
            margin-bottom: 5px;
        }

        &.lookViewButton::before {
            content: fa-content($fa-var-laptop-code);
        }

        &.viewDetailsButton::before {
            content: fa-content($fa-var-list-alt);
        }

        &.linkedReportButton::before {
            content: fa-content($fa-var-file-alt);
        }
    }

    .dz-button {
        width: 100%;
    }


    .floatingMenu {
        position: absolute;
        bottom: 100px;
        right: 60px;
    
        .floatingMenuWrapper {
            position: relative;
            display: inline-block;
    
            .itemList {
                position: absolute;
                bottom: 60px;
                transform: translateX(-50%) scale(0);
                transform-origin: center;
                transition: all 0.4s cubic-bezier(0.54, -0.10, 0.57, 0.57);
                background-color: $titlelook-gray1;
                color: $white;
                width: 200px;
                padding: 15px 0;
                text-align: left;
                border-radius: $global-radius;
                opacity: 0;
                user-select: none;
    
                div {
                    padding: 10px 30px;
    
                    a {
                        color: $white;
    
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
    
            .menuButton {
                position: absolute;
                width: 50px;
                height: 50px;
                left: 0;
                z-index: 2;
                opacity: 0;
                cursor: pointer;
    
                &:checked ~ .itemList {
                    transform: translateX(-50%) scale(1);
                    border-radius: $global-radius;
                    opacity: 1;
                    user-select: auto;
                }
    
                &:checked ~ .iconWrapper .hamburgerLine.second {
                    transform: rotate(-45deg);
                }
    
                &:checked ~ .iconWrapper .hamburgerLine.first {
                    transform: translate(1px, 5px) rotate(-135deg);
                    width: 50%;
                }
    
                &:checked ~ .iconWrapper .hamburgerLine.third {
                    transform: translate(9px, -5px) rotate(-135deg);
                    width: 50%;
                }
            }
    
            .floatingMenuHamburger {
                position: relative;
                width: 50px;
                height: 50px;
                display: inline-flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
                padding: 15px;
    
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: $global-radius;
                    background: $titlelook-orange1; /* fallback for old browsers */
                    transform: rotate(0deg);
                    transition: all 0.4s cubic-bezier(0.54, -0.10, 0.57, 0.57);
                }
    
                .hamburgerInput {
                    position: absolute;
                    opacity: 0;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    cursor: pointer;
                }
    
                .hamburgerLine {
                    width: 100%;
                    background: #fff;
                    height: 2px;
                    display: block;
                    border-radius: $global-radius;
                    transition: transform 0.4s cubic-bezier(0.54, -0.81, 0.57, 0.57);
                    position: relative;
                }
            }
        }
    }

    #floating-menu2 {
        position: absolute;
        bottom: 100px;
        right: 60px;
    }

    .accordion-title::before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-angle-down);
    }
.is-active > .accordion-title::before {
    content: fa-content($fa-var-angle-up);
}