.footer-container {
    background-color: $titlelook-gray1;
    border-top:1px solid $titlelook-gray2;
    
    .footer-row {
      color:$white;
      padding: .75rem 0;
  
      a {
        color: $white;
        text-decoration:underline;
      }
  
      .footer-right {
        text-align: right;
  
        h2 {
          color: $white;
        }
  
        p {
          font-size: 0.8rem;
          margin-bottom: 0;
        }
  
        .fa {
          padding: 1rem 0 1rem 0.2rem;
        }
      }
    }
  }
  
  